import React from "react";

import { Icon, Collapse, Button } from "@blueprintjs/core";
import { Link } from "gatsby";
// import PropTypes from 'prop-types'
// import styled from 'styled-components'

import { Col } from "./containers";

// const ErrorContainer = styled(Col)`
//   max-width: 350px;
//   & > * {
//     margin-bottom: 1rem;
//   }
//   & p {
//     text-align: left;
//   }
// `

export class ApolloError extends React.Component {
  static defaultProps = {
    showIcon: true
  };
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false
    };
  }

  render() {
    const graphQLError = this.props.graphQLError;
    if (graphQLError) {
      if (graphQLError.networkError) {
        return (
          <Col
            align="center"
            style={{
              maxWidth: 350,
              alignItems: "center",
              ...this.props.style
            }}
          >
            {this.props.showIcon ? (
              <div>
                <Icon
                  icon="offline"
                  iconSize={100}
                  style={{ color: "#137cbd" }}
                />
              </div>
            ) : null}
            {graphQLError.networkError.statusCode === 403 ? (
              <div>
                <div>Access Denied</div>
                <div>
                  Please try logging out and logging in again.
                  <Button>
                    <Link to="/logout/">Logout</Link>
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <div>A Network Error occurred.</div>
                <p> {graphQLError.message}</p>
                <p>
                  {graphQLError.networkError.result &&
                  graphQLError.networkError.result.errors
                    ? graphQLError.networkError.result.errors
                        .map(obj => obj.message)
                        .join(",")
                    : "The server is offline or you may be experiencing connection issues."}
                </p>
              </div>
            )}
          </Col>
        );
      } else {
        return (
          <Col
            align="center"
            style={{
              maxWidth: 350,
              alignItems: "center",
              ...this.props.style
            }}
          >
            <div>
              <Icon icon="error" iconSize={100} style={{ color: "#137cbd" }} />
            </div>
            <div>
              <div>An unknown Server Error Occurred.</div>
              <p> {graphQLError.message}</p>
            </div>
          </Col>
        );
      }
    }

    return null;
  }
}

// ApolloError.propTypes = {
//   showIcon: PropTypes.bool,
//   graphQLError:  PropTypes.object
// }
