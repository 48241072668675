import React from "react";

import {
  Button,
  Card,
  Divider,
  FormGroup,
  H2,
  H3,
  InputGroup,
  Intent
} from "@blueprintjs/core";
import { ErrorMessage, Form, Formik } from "formik";
import gql from "graphql-tag";
import { compose, graphql } from "react-apollo";
// import styled from 'styled-components'
import { FormErrorContainer } from "../components/forms";
import DashboardLayout from "../components/layout/dashboard";
import { ApolloError } from "../components/ErrorsContainer";
import { RaisedContainer } from "../components/raised-container";
import Spinner from "../components/Spinner";
import DeveloperAPI from "../services/DeveloperAPIClient";
import { ErrorFragment, MeFragment } from "../services/DeveloperFragments";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mutationError: null,
      showSuccessMessage: false
    };

    this.submit = this.submit.bind(this);
  }

  validate(values) {
    let errors = {};
    if (!values.name || values.name.trim() === "") {
      errors.name = "Name is a required field.";
    }
    if (!values.email || values.email.trim() === "") {
      errors.email = "Email is a required field.";
    }
    return errors;
  }

  async submit(values, actions) {
    this.setState({
      mutationError: null,
      showSuccessMessage: false
    });
    let variables = {
      input: {}
    };
    let inputCount = 0; // JS doesn't have an efficient way to count keys on an object.. #fail
    if (values.name !== this.props.meQuery.me.name) {
      variables.input.name = values.name;
      inputCount += 1;
    }
    if (values.email !== this.props.meQuery.me.email) {
      variables.input.email = values.email;
      inputCount += 1;
    }
    if (inputCount === 0) {
      actions.setSubmitting(false);
      return;
    }

    try {
      let response = await this.props.updateMe({ variables: variables });
      let validationErrors = DeveloperAPI.hasValidationErrors(
        response.data.updateMe
      );
      if (validationErrors) {
        actions.setErrors(validationErrors);
      } else {
        this.setState({ showSuccessMessage: true });
        this.props.meQuery.refetch();
      }
    } catch (error) {
      this.setState({ mutationError: error });
    } finally {
      actions.setSubmitting(false);
    }
  }

  render() {
    if (this.props.meQuery.loading) {
      return <Spinner />;
    }
    if (this.props.meQuery.error) {
      return (
        <ApolloError
          graphQLError={this.props.meQuery.error}
          style={{ margin: "0 auto", textAlign: "center" }}
        />
      );
    }
    return (
      <Formik
        initialValues={{
          email: this.props.meQuery.me.email,
          name: this.props.meQuery.me.name
        }}
        validate={this.validate}
        onSubmit={this.submit}
      >
        {({ isSubmitting, values, handleChange, errors }) => (
          <Form>
            <FormGroup
              label={"Name"}
              labelFor={"name"}
              helperText={errors.name}
            >
              <InputGroup
                placeholder={"name"}
                large
                id={"name"}
                name={"name"}
                autoComplete={"name"}
                value={values.name}
                onChange={handleChange}
              />
            </FormGroup>
            <ErrorMessage name="name" component={FormErrorContainer} />

            <FormGroup label={"Email"} labelFor={"email"}>
              <InputGroup
                placeholder={"email"}
                large
                id={"email"}
                type={"email"}
                autoComplete={"email"}
                name={"email"}
                value={values.email}
                onChange={handleChange}
              />
            </FormGroup>
            <ErrorMessage name="email" component={FormErrorContainer} />

            {this.state.showSuccessMessage ? (
              <p>Account has been updated</p>
            ) : null}

            <ApolloError
              graphQLError={this.state.mutationError}
              showIcon={false}
            />
            <Button type="submit" disabled={isSubmitting}>
              Update
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

const meQuery = gql`
  {
    me {
      ...MeFragment
    }
  }
  ${MeFragment}
`;
const UpdateMeQuery = gql`
  mutation($input: MeMutationInput!) {
    updateMe(input: $input) {
      ... on ErrorsType {
        ...ErrorTypeFragment
      }
      ... on MeType {
        ...MeFragment
      }
    }
  }
  ${ErrorFragment}
  ${MeFragment}
`;

const MeMutable = compose(
  graphql(meQuery, { name: "meQuery" }),
  graphql(UpdateMeQuery, { name: "updateMe" })
)(Profile);

const PasswordChangeSuccess = () => (
  <p>Your password has successfully been changed!</p>
);

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mutationError: null,
      showSuccessMessage: false
    };
    this.submit = this.submit.bind(this);
  }

  validate(values) {
    let errors = {};
    if (!values.currentPassword || values.currentPassword.trim() === "") {
      errors.currentPassword = "Current Password is a required field.";
    }
    if (!values.newPassword || values.newPassword.trim() === "") {
      errors.newPassword = "New Password is a required field.";
    }
    return errors;
  }

  async submit(values, actions) {
    this.setState({
      showSuccessMessage: false,
      mutationError: null
    });

    let input = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword
    };
    try {
      let response = await this.props.mutate({ variables: input });
      let validationErrors = DeveloperAPI.hasValidationErrors(
        response.data.changePassword
      );
      if (validationErrors) {
        actions.setErrors(validationErrors);
        actions.setSubmitting(false);
      } else {
        this.setState({ showSuccessMessage: true });
        // After changing a password reset the form which also resets isSubmitting
        actions.resetForm();
      }
    } catch (error) {
      this.setState({ mutationError: error });
      actions.setSubmitting(false);
    }
  }

  render() {
    return (
      <Formik
        initialValues={{ currentPassword: "", newPassword: "" }}
        validate={this.validate}
        onSubmit={this.submit}
      >
        {({ isSubmitting, values, errors, handleChange }) => (
          <Form>
            {this.state.showSuccessMessage ? <PasswordChangeSuccess /> : null}

            <ApolloError
              graphQLError={this.state.mutationError}
              showIcon={false}
            />

            <FormGroup
              label={"Current Password"}
              labelFor={"currentPassword"}
              intent={errors.currentPassword ? Intent.DANGER : Intent.NONE}
              helperText={errors.currentPassword}
            >
              <InputGroup
                placeholder={"current password"}
                large
                type={"password"}
                autoComplete={"password"}
                id={"currentPassword"}
                name={"currentPassword"}
                value={values.currentPassword}
                onChange={handleChange}
              />
            </FormGroup>
            {/*<ErrorMessage*/}
            {/*name="currentPassword"*/}
            {/*component={FormErrorContainer}*/}
            {/*/>*/}

            <FormGroup
              label={"New Password"}
              labelFor={"newPassword"}
              intent={errors.newPassword ? Intent.DANGER : Intent.NONE}
              helperText={errors.newPassword}
            >
              <InputGroup
                placeholder={"new password"}
                large
                type={"password"}
                name={"newPassword"}
                id={"newPassword"}
                value={values.newPassword}
                onChange={handleChange}
              />
            </FormGroup>
            {/*<ErrorMessage name="newPassword" component={FormErrorContainer}/>*/}
            <Button type="submit" disabled={isSubmitting}>
              Update Password
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

const ChangePasswordQuery = gql`
  mutation($currentPassword: String!, $newPassword: String!) {
    changePassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      ... on ErrorsType {
        ...ErrorTypeFragment
      }
      ... on MeType {
        ...MeFragment
      }
    }
  }
  ${ErrorFragment}
  ${MeFragment}
`;
const ChangePasswordMutable = graphql(ChangePasswordQuery)(ChangePassword);

class AccountPage extends React.Component {
  render() {
    return (
      <DashboardLayout title="Account">
        <H2>Account</H2>
        <Divider />

        <RaisedContainer padded>
          <MeMutable />
        </RaisedContainer>

        <div style={{ marginTop: 30 }}>
          <H3>Change Password</H3>
          <Divider />

          <RaisedContainer padded>
            <ChangePasswordMutable />
          </RaisedContainer>
        </div>
      </DashboardLayout>
    );
  }
}

export default AccountPage;
