import React from 'react'
import { Field } from 'formik'

const typography = {
  fontFamily: "'Helvetica Neue', 'Helvetica', Arial, sans-serif",
  fontSize: '1rem'
}

export const FormContainer = ({ children }) => (
  <div style={{ padding: 0 }}>{children}</div>
)

export const FormErrorContainer = ({ children }) => (
  <div
    style={{
      textAlign: 'center',
      color: 'red',
      marginTop: 5,
      marginBottom: 5,
      transition:
        'border 400ms ease-in-out, padding 400ms ease-in-out, box-shadow 600ms ease-in-out'
    }}>
    {children}
  </div>
)


export const StyledField = ({ children, style, className, ...rest }) => (
  <Field
    style={{
      ...typography,
      display: 'block',
      boxSizing: 'border-box',
      outline: 'none',
      padding: '10px',
      border: '1px solid #DBDBDB',
      borderRadius: '5px',
      margin: '0.5rem',
      transition:
        'border 400ms ease-in-out, padding 400ms ease-in-out, box-shadow 600ms ease-in-out',
      ...style
    }}
    className={`bai-form-field ${className}`}
    {...rest}>
    {children}
  </Field>
)
export const StyledLabel = ({ children }) => (
  <label style={typography}>{children}</label>
)
